import React from "react";

class WorkoutContainer extends React.Component {
  state = {
    selectedMoveIndex: 0,
    workoutComplete: false,
    currentTimerCount: undefined
  };

  nextMove = () =>
    this.setState(({ selectedMoveIndex }) => ({
      selectedMoveIndex: selectedMoveIndex + 1
    }));

  resetWorkout = () =>
    this.setState(_ => ({
      selectedMoveIndex: 0
    }));

  startWorkout = () => {
    // If there's no currentTimerCount we set it then proceed AFTER it has been set
    const { workout } = this.props;
    this.setState(
      state => {
        if (!state.currentTimerCount) {
          return {
            currentTimerCount: workout.moves[state.selectedMoveIndex].duration,
            workoutComplete: false
          };
        }
      },
      () => {
        this.workoutInterval = setInterval(() => {
          const { currentTimerCount } = this.state;
          const currentWorkoutIsStillInProgress = currentTimerCount > 0;
          if (currentWorkoutIsStillInProgress) {
            //dkljlkdsj
            this.setState(({ currentTimerCount }) => ({
              currentTimerCount: currentTimerCount - 1
            }));
          } else {
            this.setState(({ selectedMoveIndex }) => {
              const nextWorkoutMoveIndex = selectedMoveIndex + 1;
              const nextMove = workout.moves[nextWorkoutMoveIndex];
              if (nextMove) {
                return {
                  currentTimerCount: nextMove.duration,
                  selectedMoveIndex: nextWorkoutMoveIndex
                };
              } else {
                // this means all workout moves have been completed. We should also clear the interval
                this.clearTimer();
                return {
                  currentTimerCount: undefined,
                  workoutComplete: true,
                  selectedMoveIndex: 0
                };
              }
            });
          }
        }, 1000);
      }
    );
  };

  clearTimer = () =>
    this.workoutInterval && clearInterval(this.workoutInterval);

  componentWillUnmount() {
    this.clearTimer();
  }

  render() {
    const {
      selectedMoveIndex,
      currentTimerCount,
      workoutComplete
    } = this.state;

    const { workout } = this.props;
    const currentlySelectedVideoUrl = workout.moves[selectedMoveIndex].videoUrl;
    const workoutInProgress =
      typeof currentTimerCount !== "undefined" &&
      typeof currentTimerCount == "number";

    const screenProps = {
      selectedMoveIndex,
      currentTimerCount,
      workoutComplete,
      workout,
      currentlySelectedVideoUrl,
      workoutInProgress,
      startWorkout: this.startWorkout
    };

    return this.props.children(screenProps);
  }
}

export default WorkoutContainer;
