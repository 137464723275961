import React from "react";
import Layout from "../components/layout";
import WorkoutContainer from "../containers/workout";
import WorkoutScreen from "../screens/WorkoutScreen";

const WorkoutPage = () => {
  return (
    <Layout>
      <WorkoutContainer workout={workoutJSON}>
        {screenProps => <WorkoutScreen {...screenProps} />}
      </WorkoutContainer>
    </Layout>
  );
};

const workoutJSON = {
  title: `7 Minute No Equipment Workout`,
  description: `Define your curves, get stronger and leaner with this no equipment workout. From your shoulders to your core, glutes and legs, this workout hits every angle in just 7 minutes!. Be sure to complete entire circuit 2xce!`,
  duration: 7,
  difficulty: "Advanced Beginner", // enum
  equipment: "No equipment",
  focus: ["Upper", "Lower Body"], // enum
  moves: [
    {
      name: "Squats",
      duration: 30,
      rest: 12,
      videoUrl:
        // "https://res.cloudinary.com/dreyahfit/video/upload/v1559611023/testworkoutvideo164875.mp4",
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560886198/Squats_n8if4q.mp4",
      instructions:
        "Stand straight with feet hip-width apart, Put your shoulders back, lift your chest, and pull in your abdominal muscles. Lower down, as if sitting."
    },
    {
      name: "Break",
      duration: 12,
      rest: 12,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560894682/Jumo_Squats_Break_ymjerp.mp4",
      instructions: "12 Second Break."
    },
    {
      name: "Jump Squats",
      duration: 30,
      rest: 10,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560888978/Jump_Squats_n4kvn2.mp4",
      instructions:
        "Start with regular squat stance, engage your core and jump up explosively. Be sure to land softly."
    },
    {
      name: "Break",
      duration: 12,
      rest: 12,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560895803/Walk_out_to_shoulder_taps_Break_tezhkt.mp4",
      instructions: "12 Second Break."
    },
    {
      name: "Walk out to shoulder taps",
      duration: 30,
      rest: 10,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560890483/Walkout_to_shoulder_taps_srktsb.mp4",
      instructions:
        "Walk out with both hands and tap each shoulder. Be sure to keep your core stablized."
    },
    {
      name: "Break",
      duration: 12,
      rest: 12,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560895998/back_lunges-_BREAK_vdmv5e.mp4",
      instructions: "12 Second Break."
    },
    {
      name: "Single Leg Back Lunges",
      duration: 15,
      rest: 10,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560891160/Single_Leg_back_lunges_-_left_leg_yow6gt.mp4",
      instructions:
        "Take a large step backward with your left foot. Lower your hips so that your right thigh (front leg) becomes parallel to the floor with your right knee positioned directly over your ankle. Your left knee should be bent at a 90-degree angle and pointing toward the floor with your left heel lifted."
    },
    {
      name: "Single Leg Back Lunges",
      duration: 15,
      rest: 10,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560891817/Single_Leg_back_lunges_-_right_leg_zqhtns.mp4",
      instructions: "Right Leg goes back."
    },
    {
      name: "Break",
      duration: 12,
      rest: 12,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560896326/push_ups-_BREAK_yynbje.mp4",
      instructions: "12 Second Break."
    },
    {
      name: "Push Ups",
      duration: 30,
      rest: 10,
      videoUrl:
        "https://res.cloudinary.com/dreyahfit/video/upload/v1560892950/push_ups_byhq7l.mp4",
      instructions: "Ok to do modified pushups with knees on the floor"
    }
  ]
};

export default WorkoutPage;
