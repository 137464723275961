import React from "react";
import styled from "styled-components";
import ViewMoreButton from "../components/ViewMoreButton";

const WorkoutEl = styled.div`
  margin-bottom: 88px;

  .workout-details {
    max-width: 640px;
    margin: auto;
    padding: 0 24px;

    .workout-description {
      font-size: 0.9rem;
    }

    @media only screen and (max-width: 640px) {
      max-width: auto;
    }
  }
`;

const WorkoutDashboardEl = styled.div`
  display: flex;
  max-width: 1200px;
  padding: 16px 24px;
  margin: auto;
  margin-bottom: 16px;

  h1.workout-title {
    /* careful adding styles here so it doesn't get applied to section-right h1 */
    margin-bottom: 1rem;
    display: none;
  }

  .section-left {
    width: 60%;
    background: ${({ workoutInProgress }) =>
      workoutInProgress ? "none" : "#eee"};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .workout-video {
      width: 100%;
    }

    button {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      border: 0;
      padding: 12px;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      background: #0e0e0e;
      z-index: 10;
      cursor: pointer;

      &:hover {
        background: #242424;
      }
    }
  }

  .section-right {
    width: 40%;
    padding: 0 36px;
    box-sizing: border-box;

    h1.workout-title {
      margin-bottom: 3.45rem;
      display: block;
    }

    .secs-remaining {
      text-transform: uppercase;
      font-weight: lighter;
      opacity: 0.75;
      /* text-align: center; */

      span {
        opacity: 1;
        font-size: 5rem;
        font-weight: bold;
        color: #000;
        display: block;
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    max-width: auto;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;

    h1.workout-title {
      display: block;
      text-align: center;
    }

    .section-left {
      width: auto;
      margin-bottom: 24px;
      height: 400px;
    }

    .section-right {
      width: auto;
      display: flex;

      div {
        width: 50%;

        section:first-child {
          margin-top: 0;
        }
      }

      h1.workout-title {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 414px) {
    margin-bottom: 0;

    .section-left {
      height: 184px;
    }
  }
`;

const WorkoutScreen = ({
  selectedMoveIndex,
  currentTimerCount,
  workoutComplete,
  workout,
  currentlySelectedVideoUrl,
  workoutInProgress,
  startWorkout
}) => {
  return (
    <WorkoutEl>
      <WorkoutDashboardEl workoutInProgress={workoutInProgress}>
        <h1 className="workout-title">{workout.title}</h1>
        <section className="section-left">
          {workoutInProgress && !workoutComplete ? (
            <video
              className="workout-video"
              key={currentlySelectedVideoUrl}
              playsInline
              muted
              autoPlay
              loop
            >
              <source src={currentlySelectedVideoUrl} type="video/mp4" />
            </video>
          ) : (
            <EmptyWorkoutVideoScreen
              workoutInProgress={workoutInProgress}
              workoutComplete={workoutComplete}
            />
          )}
          {!workoutInProgress && (
            <button onClick={startWorkout}>
              {`${workoutComplete ? "restart workout" : "start workout"}`}
            </button>
          )}
        </section>
        <section className="section-right">
          <div>
            <h1 className="workout-title">{workout.title}</h1>
            <h5 className="secs-remaining">
              Seconds Remaining: <span>{currentTimerCount || 0}</span>
            </h5>
          </div>
          <div>
            <WorkoutStat statKey="duration" workout={workout} />
            <WorkoutStat statKey="difficulty" workout={workout} />
            <WorkoutStat statKey="equipment" workout={workout} />
            <WorkoutStat statKey="focus" workout={workout} />
          </div>
        </section>
      </WorkoutDashboardEl>

      <div className="workout-details">
        <p className="workout-description">{workout.description}</p>
        {workout.moves.map((move, id) => (
          <WorkoutDetail
            moveIsInProgress={selectedMoveIndex == id && workoutInProgress}
            {...move}
            key={id}
          />
        ))}
      </div>
    </WorkoutEl>
  );
};

// Workout Stat
const WorkoutStatEl = styled.section`
  font-size: 75%;
  margin: 10px 0 16px;

  .workout-stat-label {
    text-transform: uppercase;
    font-weight: lighter;
    opacity: 0.75;
  }

  .workout-stat-value {
    text-transform: capitalize;
  }
`;

const WorkoutStat = ({ statKey, workout }) => {
  const statValue = workout[statKey];
  return (
    <WorkoutStatEl>
      <span className="workout-stat-label">{statKey}: </span>
      <span className="workout-stat-value">
        {Array.isArray(statValue) ? statValue.join(", ") : statValue}
      </span>
    </WorkoutStatEl>
  );
};

// EmptyWorkoutScreen
const EmptyWorkoutVideoScreenEl = styled.div`
  padding: 32px;
  text-align: center;

  h5 {
    font-size: 1.3rem;
  }
`;

const EmptyWorkoutVideoScreen = ({ workoutInProgress, workoutComplete }) => {
  return (
    <EmptyWorkoutVideoScreenEl>
      {workoutComplete ? (
        <h5>
          That was awesome! Pick another workout or hit restart to go again
        </h5>
      ) : !workoutInProgress ? (
        <h5>Ready? Press start to begin!</h5>
      ) : null}
    </EmptyWorkoutVideoScreenEl>
  );
};

// Workout Detail Component
const WorkoutDetailEl = styled.div`
  display: flex;
  margin-bottom: 32px;
  width: 100%;

  section.video-thumbnail {
    margin-right: 24px;
    box-sizing: border-box;

    video {
      border: ${({ moveIsInProgress }) =>
        moveIsInProgress ? `6px solid purple` : `4px solid rgba(0,0,0,0)`};
    }
  }

  .move-details {
    h3 {
      font-size: 1rem;
      margin-bottom: 0px;
    }

    span {
      font-size: 0.8rem;
      opacity: 0.7;
    }

    .move-instructions {
      font-size: 82%;
    }
  }

  @media only screen and (max-width: 414px) {
    flex-direction: column;
    align-items: center;

    section.video-thumbnail {
      margin-right: 0;
    }

    .move-details {
      width: 254px;
    }
  }
`;

const WorkoutDetail = ({
  name,
  duration,
  videoUrl,
  instructions,
  rest,
  moveIsInProgress
}) => {
  return (
    <WorkoutDetailEl moveIsInProgress={moveIsInProgress}>
      <section className="video-thumbnail">
        <video style={{ width: 254 }} playsInline muted>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </section>

      <div className="move-details">
        <h3>{name}</h3>
        <span>{duration} secs</span>
        <p className="move-instructions">{instructions}</p>
      </div>
    </WorkoutDetailEl>
  );
};

export default WorkoutScreen;
